module.exports = {
  cronDelay: 300000,
  filesSettings: {
    size: 1024 * 1024 * 8,
    ordoclicFileSize: 1024 * 1024 * 5,
    timeout: 1666667
  },
  excelExportMaxCount: 10000,
  maxPatientNameLength: 35
};
