/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { fetchFile, searchDelegates, uploadPrivateFile, searchInsureds } from "@/modules/common/_api";
import bindDeliveriesData from "@/helpers/_functions/bindDeliveriesData";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  updatePatientActiveContractsNextConsultationDate(context, { nextConsultationDate, patientId }) {
    return new Promise((resolve, reject) => {
      api
        .updatePatientActiveContractsNextConsultationDate(nextConsultationDate, patientId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editContract(context, { id, ...contractData }) {
    if (contractData.deliveries) {
      contractData.deliveries = bindDeliveriesData(contractData.deliveries);
    }

    return api.editPatientContract(id, contractData);
  },
  editPatientInformations(context, { _id, ...patientData }) {
    return api.editPatientInformations(_id, patientData);
  },
  editPatientFromIntervention(context, { _id, ...data }) {
    return api.editPatientFromIntervention(_id, data);
  },
  editInsuredInformations(context, data) {
    const { _id, ...insured } = data;
    return api.editInsured(_id, insured);
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  fetchInsureds(context, name) {
    return new Promise((resolve, reject) => {
      searchInsureds({ query: { name } })
        .then(res => {
          resolve(res.data.body.results);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchInsureds(context, input) {
    return new Promise((resolve, reject) => {
      searchInsureds({ query: { input }, skip: 0, limit: 40, source: "remote" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchDelegates(context, input) {
    return searchDelegates({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  getRenewalDocumentById(context, id) {
    return api.getRenewalDocumentById(id);
  },
  addCustomProtocol(context, data) {
    return api.addCustomProtocol(data);
  },
  addProductsReminder(context, data) {
    return api.addProductsReminder(data);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
