import isEmpty from "lodash/isEmpty";

import { fetchEquipementInformations, fetchInStockByCipWithMaintenanceInfos } from "@/modules/common/_api";

const state = {
  loadingEquipementInformations: false,
  equipementInformations: {}
};

const getters = {
  loadingEquipementInformations: state => state.loadingEquipementInformations,
  equipementInformations: state => state.equipementInformations
};

const mutations = {
  SET_EQUIPEMENT_INFORMATIONS: (state, equipementInformations) => {
    state.equipementInformations = equipementInformations;
  },
  UPDATE_EQUIPEMENT_INFORMATIONS_LOADING: (state, loadingEquipementInformations) => {
    state.loadingEquipementInformations = loadingEquipementInformations;
  },
  RESET_EQUIPEMENT_INFORMATIONS_STATE: state => {
    state.loadingEquipementInformations = false;
    state.equipementInformations = {};
  }
};

const actions = {
  checkSerialNumberValidity(context, serialNumber) {
    return new Promise(resolve => {
      fetchEquipementInformations({ serialNumber })
        .then(resGetEquipement => {
          const equipementInformations = resGetEquipement?.data?.body || null;
          if (!equipementInformations || !equipementInformations.productId) {
            return resolve(null);
          }
          return resolve(equipementInformations);
        })
        .catch(() => resolve(null));
    });
  },
  fetchEquipementInformations(context, delivery) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_EQUIPEMENT_INFORMATIONS_LOADING", true);
      const { fabricantNumber, serialNumber } = delivery;
      const formattedSerialNumber = serialNumber.length > 11 ? serialNumber.substring(serialNumber.length - 11) : serialNumber;

      fetchEquipementInformations({ serialNumber: formattedSerialNumber, fabricantNumber })
        .then(resGetEquipement => {
          const equipementInformations = resGetEquipement.data.body || null;
          if (equipementInformations && equipementInformations.productId) {
            Object.assign(equipementInformations, { deliveryId: delivery._id });
            context.commit("SET_EQUIPEMENT_INFORMATIONS", equipementInformations);
            context.commit("UPDATE_EQUIPEMENT_INFORMATIONS_LOADING", false);
            resolve();
          } else {
            context.commit("SET_EQUIPEMENT_INFORMATIONS", null);
            context.commit("UPDATE_EQUIPEMENT_INFORMATIONS_LOADING", false);
            resolve();
          }
        })
        .catch(err => {
          context.commit("SET_EQUIPEMENT_INFORMATIONS", null);
          context.commit("UPDATE_EQUIPEMENT_INFORMATIONS_LOADING", false);
          reject(err);
        });
    });
  },
  getEquipementInformations(context, delivery) {
    return new Promise((resolve, reject) => {
      if (!isEmpty(context.state.equipementInformations) && context.state.equipementInformations.deliveryId === delivery._id) {
        resolve(context.state.equipementInformations);
        return;
      }

      context
        .dispatch("fetchEquipementInformations", delivery)
        .then(() => {
          if (!isEmpty(context.state.equipementInformations) && context.state.equipementInformations.deliveryId === delivery._id) {
            resolve(context.state.equipementInformations);
          } else {
            reject();
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetEquipementInformationsState(context) {
    context.commit("RESET_EQUIPEMENT_INFORMATIONS_STATE");
  },
  fetchInStockByCipWithMaintenanceInfos(context, params) {
    return new Promise((resolve, reject) => {
      if (isEmpty(params.stockIds) || !params.productId) {
        return resolve();
      }
      fetchInStockByCipWithMaintenanceInfos({ ...params, maintenanceInfos: true })
        .then(res => {
          if (res?.data?.body) {
            return resolve(res.data.body);
          }
        })
        .catch(err => reject(err));
    });
  }
};

export default { state, getters, mutations, actions };
