const capitalize = require("lodash/capitalize");
const isString = require("lodash/isString");

/**
 * @author Alexandre POIRRIER
 * @date 2025-03-04
 * @description Capitalize words in str
 *
 * @param {String} str
 * @returns {String} The formatted string
 */
const capitalizeString = str => {
  if (!isString(str)) {
    return "";
  }

  return str
    .split(" ")
    .map(word => word.split("-").map(capitalize).join("-"))
    .join(" ");
};

module.exports = capitalizeString;
