import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import NotFound from "@/views/404";
import BadGateway from "@/views/502";
import Unauthorized from "@/views/401";
import Forbidden from "@/views/403";

const localPath = "router.app";

export default [
  {
    path: "404",
    alias: "*",
    name: "notFound",
    component: NotFound,
    props: true,
    meta: {
      title: localize(localPath, "title--notFound"),
      public: true
    }
  },
  {
    path: "502",
    name: "badGateway",
    component: BadGateway,
    props: true,
    meta: {
      title: localize(localPath, "title--badGateway"),
      public: true
    }
  },
  {
    path: "401",
    name: "Unauthorized",
    component: Unauthorized,
    props: true,
    meta: {
      title: localize(localPath, "title--unauthorized"),
      public: true
    }
  },
  {
    path: "403",
    name: "Forbidden",
    component: Forbidden,
    props: true,
    meta: {
      title: localize(localPath, "title--forbidden"),
      public: true
    }
  }
];
