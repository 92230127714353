/** The clickOutside directive triggers a method when a click is detected outside element.
 * Usage: <div v-click-outside="closeEvent">
 * */

const generateOnMouseDownEvent = (element, binding) => {
  return event => {
    if (!(element === event.target || element.contains(event.target))) {
      binding.value(event);
    }
  };
};

export const clickOutside = {
  bind: (element, binding) => {
    if (typeof binding.value === "function") {
      const onMouseDownEvent = generateOnMouseDownEvent(element, binding);

      const once = binding.modifiers?.once || false;

      document.body.addEventListener("mousedown", onMouseDownEvent, { once });
    }
  },
  unbind: (element, binding) => {
    const onMouseDownEvent = generateOnMouseDownEvent(element, binding);

    document.body.removeEventListener("mousedown", onMouseDownEvent);
  }
};
