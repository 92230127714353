import { iconAlert, iconDashboard, iconMailOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const {
  alertsRights: { ACCESS_ALERT },
  alertsTemplatesRights: { ACCESS_ALERT_TEMPLATE }
} = require("@common/services/acm/rights");

const localPath = "menus.administration.alert";

const subMenuAlerts = {
  title: localize(localPath, "title"),
  slug: "alerts-sub",
  links: [
    {
      icon: iconDashboard,
      title: localize(localPath, "alerts.link--alertsDashboard"),
      routerLink: {
        name: "alertsDashboard"
      },
      rights: [ACCESS_ALERT]
    },
    {
      icon: iconAlert,
      title: localize(localPath, "alerts.link--alertTemplatesList"),
      routerLink: {
        name: "alertTemplatesList"
      },
      rights: [ACCESS_ALERT_TEMPLATE]
    },
    {
      icon: iconMailOutlined,
      title: localize(localPath, "alerts.link--alertsReminders"),
      routerLink: {
        name: "alertsReminders"
      },
      rights: [ACCESS_ALERT]
    }
  ]
};

const menuAlerts = {
  title: localize(localPath, "title"),
  slug: "alerts",
  icon: iconAlert,
  disabled: false,
  items: [subMenuAlerts]
};

export default menuAlerts;
