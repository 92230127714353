import { iconSettings, iconTags } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuUsers from "./subMenuUsers";

const localPath = "menus.administration.settings";

const {
  usersRights: { VIEW_USER },
  supportTicketsRights: { ACCESS_SUPPORT_TICKETS }
} = require("@common/services/acm/rights");

const features = require("../../../services/features");

const menuUsersLogs = {
  title: localize(localPath, "title"),
  slug: "settings",
  icon: iconSettings,
  notificationName: "countUnreadSupportTickets",
  disabled: false,
  rights: [VIEW_USER],
  items: [subMenuUsers]
};

if (__APP_ENV__ === "production") {
  menuUsersLogs.items.push({
    title: localize("menus.administration.settings.tickets.title"),
    slug: "tickets",
    feature: features.isSupportEnabled,
    links: [
      {
        icon: iconTags,
        notificationName: "countUnreadSupportTickets",
        slug: "tickets",
        title: localize("menus.administration.settings.tickets.link--ticketsList"),
        routerLink: {
          name: "supportTicketsList"
        },
        rights: [ACCESS_SUPPORT_TICKETS]
      }
    ]
  });
}

export default menuUsersLogs;
