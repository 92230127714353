import http from "@/services/http";

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 *
 * @param {String} stockId
 *
 * @returns {Promise<Object>} stocks
 *
 */
export const fetchHsatStocks = (stockId, params) =>
  http.get(`/stocks/hsat/${stockId}`, {
    params
  });

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 *
 * @param {String} stockId
 *
 * @returns {Promise<Array>} stock items
 *
 */
export const getStockItems = async stockId => http.get(`/stocks/${stockId}/items`);

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 *
 * @param {String} itemId
 *
 * @returns {Promise}
 *
 */
export const deleteStockItem = async itemId => http.delete(`/stocks/item/${itemId}`);

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 * 
 * @param {String} stockId
 * @param {Array<Object>} items
 * 
 * @returns {Promise}
 *

 */
export const addStockItems = async (stockId, items) => http.post(`/stocks/${stockId}/items`, items);

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 *
 * @param {String} productId
 * @param {String} serialNumber
 *
 * @returns {Promise<Object | Error>}
 *
 */
export const isSerialNumberAvailable = async (productId, serialNumber) => http.get(`/stocks/products/${productId}/serial-number/${serialNumber}`);

/**
 * @author Alexandre POIRRIER
 * @date 2024-02-18
 *
 * @param {Object} params
 *
 * @returns {Promise<[Object]>}
 *
 */
export const searchHsatSerialNumber = async params => http.get("/stocks/items/search", { params });
