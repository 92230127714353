import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.exports";

const ExportMouvementsStock = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/mouvements-stock");
const ExportDeliveredPairableDevices = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/delivered-pairable-devices");
const ExportConsommations = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/consommations");
const ExportReplenishmentPacks = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/replenishment-packs");
const GetExport = () => import(/* webpackChunkName: "exports" */ "@/modules/exports/get-export");

const {
  menuRights: {
    VIEW_MENU_EXPORT_REPLENISHMENT_PACKS,
    VIEW_MENU_EXPORT_MOUVEMENTS_STOCK,
    VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES,
    VIEW_MENU_EXPORT_CONSOMMATIONS
  }
} = require("@common/services/acm/rights");

export default [
  {
    path: "export-replenishment-packs",
    alias: "export-replenishment-packs",
    name: "exportReplenishmentPacks",
    component: ExportReplenishmentPacks,
    props: true,
    meta: {
      title: localize(localPath, `title--exportReplenishmentPacks`),
      rights: [VIEW_MENU_EXPORT_REPLENISHMENT_PACKS]
    }
  },
  {
    path: "export-stock-movements",
    alias: "export-mouvements-stock",
    name: "exportMouvementsStock",
    component: ExportMouvementsStock,
    props: true,
    meta: {
      title: localize(localPath, `title--exportMouvementsStock`),
      rights: [VIEW_MENU_EXPORT_MOUVEMENTS_STOCK]
    }
  },
  {
    path: "export-pairings",
    alias: "export-appairages",
    name: "exportDeliveredPairableDevices",
    component: ExportDeliveredPairableDevices,
    props: true,
    meta: {
      title: localize(localPath, `title--exportDeliveredPairableDevices`),
      rights: [VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES]
    }
  },
  {
    path: "export-consumptions",
    alias: "export-consommations",
    name: "exportConsommations",
    component: ExportConsommations,
    props: true,
    meta: {
      title: localize(localPath, `title--exportConsommations`),
      rights: [VIEW_MENU_EXPORT_CONSOMMATIONS]
    }
  },
  {
    path: "exports/:id",
    name: "getExport",
    component: GetExport,
    meta: {
      title: localize(localPath, `title--getExport`),
      private: true
    }
  }
];
