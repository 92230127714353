import http from "@/services/http";

/**
 *
 * @param {String} prescriberId
 *
 * @returns {Promise}
 */
export const getPrescriberSleepTrainingTypeId = async prescriberId => {
  try {
    const response = await http.get(`/prescribers/${prescriberId}/sleep-training-type-id`);
    return response.data.body;
  } catch (error) {
    return null;
  }
};

/**
 *
 * @param {String} prescriberId
 * @param {String} sleepTrainingTypeId
 *
 * @returns {Promise}
 */
export const updatePrescriberSleepTrainingTypeId = (prescriberId, sleepTrainingTypeId) => {
  return http.put(`/prescribers/${prescriberId}/sleep-training-type-id`, { sleepTrainingTypeId });
};
