const isEmpty = require("lodash/isEmpty");

/**
 * This helpers is used to define if a feature can be used depending on the context poles / channels / rpps ...
 * At least one valid option in needed to use the function.
 *
 * @param {Object} options
 * * @param {Array<String>} [options.poleIds]
 * * @param {Array<String>} [options.channelIds]
 * * @param {Array<String>} [options.rpps]
 *
 * @param {Object} pilot
 * * @param {Array<String>} [pilot.pilotPoles]
 * * @param {Array<String>} [pilot.pilotChannels]
 * * @param {Array<String>} [pilot.pilotRpps]
 *
 * @returns {Boolean}
 * @author Alexandre POIRRIER
 * @date 2024-12-01
 */
const isPilot = (options = {}, pilot = {}) => {
  // If there is no pilot defined, then the feature is available
  if (isEmpty(pilot) || (isEmpty(pilot.pilotPoles) && isEmpty(pilot.pilotChannels) && isEmpty(pilot.pilotRpps) && isEmpty(pilot.pilotUsers))) {
    return true;
  }

  // If there is no options, the feature can not be access
  if (isEmpty(options)) {
    return false;
  }

  if (options.poleIds && pilot.pilotPoles?.some(pilotPole => options.poleIds.includes(pilotPole))) {
    return true;
  }

  if (options.channelIds && pilot.pilotChannels?.some(pilotChannel => options.channelIds.includes(pilotChannel))) {
    return true;
  }

  if (options.rpps && pilot.pilotRpps?.some(pilotRpps => options.rpps.includes(pilotRpps))) {
    return true;
  }

  if (options.ids && pilot.pilotUsers?.some(pilotUser => options.ids.includes(pilotUser))) {
    return true;
  }

  return false;
};

module.exports = isPilot;
