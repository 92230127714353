const platforms = {
  bastide: "bastide",
  diabete: "bastide-diabete",
  lorair: "lorair",
  intus: "intus",
  demo: "demo",
  medpro: "medpro"
};

module.exports = platforms;
