import http from "@/services/http";

/**
 * @author Alexandre POIRRIER
 * @date 2025-03-26
 *
 * @param {Object} query
 * * @param {Date} query.startDate
 * * @param {Date} query.endDate
 * * @param {String} query.userId
 * * @param {String} query.interventionId
 *
 * @returns {Promise<Object>}
 */

export default query => http.get(`/users/check-planning-slot-availability`, { params: query });
