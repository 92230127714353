import * as api from "../_api";
import { fetchStocks } from "@/modules/common/_api";

const state = {
  userPoles: [],
  hsatStocksList: []
};

const getters = {
  hsatStocksList: state => state.hsatStocksList,
  loading: state => state.loading
};

const mutations = {
  SET_HSAT_STOCKS_LIST: (state, hsatStocksList) => {
    state.hsatStocksList = hsatStocksList;
  }
};

const actions = {
  async fetchHsatStocks(context, { query }) {
    const response = await fetchStocks({ query: { poleIds: query.poleIds, stockTypeIds: ["OF"] }, skip: query.skip, limit: query.limit });

    context.commit("SET_HSAT_STOCKS_LIST", response.data?.body || []);
  },
  getStockItems(context, stockId) {
    return api.getStockItems(stockId);
  },
  deleteStockItem(context, itemId) {
    return api.deleteStockItem(itemId);
  },
  addStockItems(context, { stockId, items }) {
    return api.addStockItems(stockId, items);
  },
  isSerialNumberAvailable(context, { idToCheck, dataToCheck }) {
    return api.isSerialNumberAvailable(idToCheck, dataToCheck);
  },
  searchHsatSerialNumber(context, input) {
    const query = { input };

    return api.searchHsatSerialNumber(query);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
