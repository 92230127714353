import * as uuid from "uuid";

/**
 * Valeur de test staging ordoclic
  //  "as-i-591021191-01-851-7171-1733222717" Favorable
  //  "as-i-591021191-01-851-7171-1733222450"
  //  "as-i-591021191-01-851-7171-1733221193"
 */
export default (prescriptionsToCheck = []) => {
  const batchOperations = [];
  const prescriptionInfosBeforeBatchOperations = [];

  prescriptionsToCheck.forEach(prescription => {
    const messageId = uuid.v4();
    batchOperations.push({
      messageId,
      messageType: "amelipro.transmission",
      payload: {
        formType: "dap-status",
        inputs: {
          recherche: {
            filtre: prescription.ameliproDocumentId
          }
        }
      }
    });

    prescriptionInfosBeforeBatchOperations.push({
      messageId,
      prescriptionId: prescription.prescriptionId,
      contractId: prescription.contractId,
      initiale: prescription.initiale,
      ameliproDocumentId: prescription.ameliproDocumentId,
      ameliproStatusId: prescription.ameliproStatusId
    });
  });

  return { batchOperations, prescriptionInfosBeforeBatchOperations };
};
