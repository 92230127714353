/**
 * Build the prescriptions list for update on our side based on the batch results and the previous prescriptions statuses.
 * It only returns an array of objects that contains the ameliproDocumentId and the statusId, where the statusId has been updated
 * @author MaximeItaf
 * @date 2025-01-22
 *
 * @param {Array} batchResults - Results emit by the ordoclic extension operation
 * @param {Array<Object>} prescriptionInfosBeforeBatchOperations - Old prescriptions amelipro infos needed to link the result to the prescription
 * * @param {String} prescriptionInfosBeforeBatchOperations._id
 * * @param {String} prescriptionInfosBeforeBatchOperations.messageId
 * * @param {String} prescriptionInfosBeforeBatchOperations.ameliproDocumentId
 * * @param {Boolean} prescriptionInfosBeforeBatchOperations.initiale
 * * @param {String} prescriptionInfosBeforeBatchOperations.contractId
 * @returns {Array<Object>}
 */
export default (batchResults, prescriptionInfosBeforeBatchOperations) => {
  if (!batchResults.length || !prescriptionInfosBeforeBatchOperations.length) {
    return [];
  }

  const updatedStatusPrescriptions = [];

  batchResults.forEach(result => {
    const resultAmeliproStatusId = result.payload?.outputs?.recherche?.avis;
    const prescriptionToUpdate = prescriptionInfosBeforeBatchOperations.find(operation => operation.messageId === result.responseToMessageId);

    if (!prescriptionToUpdate) {
      return;
    }

    if (!resultAmeliproStatusId || !prescriptionToUpdate.ameliproDocumentId) {
      return;
    }

    if (prescriptionToUpdate.ameliproStatusId !== resultAmeliproStatusId) {
      updatedStatusPrescriptions.push({
        ameliproStatusId: resultAmeliproStatusId,
        ameliproDocumentId: prescriptionToUpdate.ameliproDocumentId,
        prescriptionId: prescriptionToUpdate.prescriptionId,
        initiale: prescriptionToUpdate.initiale,
        contractId: prescriptionToUpdate.contractId
      });
    }
  });

  return updatedStatusPrescriptions;
};
