module.exports = {
  externalService: "native",
  customIdGeneration: "incremental",
  defaultDivisionId: "R",
  handledDivisionIds: ["R"],
  overridedModules: [
    // PATIENTS
    { key: "PatientsListAdmin", value: "PatientsListAdminMedpro" },
    { key: "PatientView", value: "PatientViewMedpro" },
    { key: "PatientViewRespiratory", value: "PatientViewRespiratoryMedpro" },
    { key: "PatientAddForm", value: "PatientAddFormMedpro" },
    { key: "PatientEditContract", value: "PatientEditContractMedpro" },
    { key: "PatientEditInformations", value: "PatientEditInformationsMedpro" },
    { key: "PatientViewInformations", value: "PatientViewInformationsMedpro" },

    // PRESCRIBERS
    { key: "IndexPrescriberView", value: "IndexPrescriberViewMedpro" },
    { key: "IndexPagePrescriberAddForm", value: "IndexPagePrescriberAddFormMedpro" },
    { key: "TabPrescriberViewInternalInformations", value: "TabPrescriberViewInternalInformationsMedpro" },
    { key: "TabPrescriberViewGlobalInformations", value: "TabPrescriberViewGlobalInformationsMedpro" },
    { key: "IndexPagePrescribersList", value: "IndexPagePrescribersListMedpro" },
    { key: "PrescriberEditInternalInformations", value: "PrescriberEditInternalInformationsMedpro" },
    { key: "PrescriberEditGlobalInformations", value: "PrescriberEditGlobalInformationsMedpro" },

    // APPOINTMENT (INTERVENTIONS)
    { key: "IndexPageInterventionList", value: "IndexPageInterventionListMedpro" },
    { key: "InterventionContract", value: "InterventionContractMedpro" },
    { key: "InterventionEditContract", value: "InterventionEditContractMedpro" },
    { key: "ReadyStatus", value: "ReadyStatusMedpro" },
    { key: "InterventionsListValidatedStatus", value: "InterventionsListValidatedStatusMedpro" },
    { key: "WaitingStatus", value: "WaitingStatusMedpro" },

    // CONTRACTS
    { key: "ContractsAdd", value: "ContractsAddMedpro" },
    { key: "ContractsAddPatient", value: "ContractsAddPatientMedpro" },
    { key: "ContractsAddForfait", value: "ContractsAddForfaitMedpro" },

    // DASHBOARD
    { key: "IndexDashboardAdmin", value: "IndexDashboardAdminMedpro" },

    // COMMUNITY
    { key: "IndexCommunitiesList", value: "IndexCommunitiesListMedpro" },
    { key: "IndexCommunityPage", value: "IndexCommunityPageMedpro" },
    { key: "CommunityAdd", value: "CommunityAddMedpro" },
    { key: "CommunityAddForm", value: "CommunityAddFormMedpro" },
    { key: "IndexCommunityEditGlobalInformations", value: "IndexCommunityEditGlobalInformationsMedpro" },
    { key: "IndexCommunityViewGlobalInformations", value: "IndexCommunityViewGlobalInformationsMedpro" },
    { key: "IndexCommunityEditInternalInformations", value: "IndexCommunityEditInternalInformationsMedpro" },
    { key: "IndexCommunityViewInternalInformations", value: "IndexCommunityViewInternalInformationsMedpro" },
    { key: "IndexCommunityPatientsList", value: "IndexCommunityPatientsListMedpro" },
    { key: "IndexCommunityStock", value: "IndexCommunityStockMedpro" },

    // USER
    { key: "IndexAddUser", value: "IndexAddUserMedpro" },
    { key: "UsersSearch", value: "UsersSearchMedpro" },
    { key: "UsersAddForm", value: "UsersAddFormMedpro" },
    { key: "UsersEditInternalInformations", value: "UsersEditInternalInformationsMedpro" },
    { key: "IndexUserView", value: "IndexUserViewMedpro" },
    { key: "IndexUsersList", value: "IndexUsersListMedpro" }
  ],
  overridedSchemas: [
    { key: "patient", value: "patientMedpro" },
    { key: "userCreation", value: "userCreationMedpro" },
    { key: "userInternals", value: "userInternalsMedpro" },
    { key: "defaultSchemaPrescriber", value: "schemaPrescriberMedpro" },
    { key: "prescriber-internals", value: "prescriberMedpro-internals" },
    { key: "prescriber-globals", value: "prescriberMedpro-globals" },
    { key: "contractAdd", value: "contractAddMedpro" },
    { key: "contractEdit", value: "contractEditMedpro" },
    { key: "communityCreation", value: "communityCreationMedpro" },
    { key: "communityGlobalEdition", value: "communityGlobalEditionMedpro" }
  ],
  relationPatientIntervenantMode: "userId",
  communityPoleFilterMode: "channelId",
  nameFormatInEmail: {
    toPatientTargetName: "firstname",
    toPatientSenderName: "firstname",
    toPrescriberTargetName: "fullname",
    toPrescriberSenderName: "fullname",
    toAdminTargetName: "firstname",
    toAdminSenderName: "fullname"
  }
};
