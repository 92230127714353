const FETCH_TAXONOMIES = "fetchTaxonomies";

export default {
  additionalNeedsRespiration: FETCH_TAXONOMIES,
  aerosolModels: FETCH_TAXONOMIES,
  agaProducts: "fetchAgaProducts",
  hsatProducts: "fetchHsatProducts",
  alertReminders: FETCH_TAXONOMIES,
  alertStatuses: FETCH_TAXONOMIES,
  alertTemplateMonitoringTypes: FETCH_TAXONOMIES,
  alertTemplateRules: FETCH_TAXONOMIES,
  alertTemplateRulesValues: FETCH_TAXONOMIES,
  alertTemplatePeriodDuration: FETCH_TAXONOMIES,
  alertTemplates: "fetchAlertTemplates",
  alertTemplateTypes: FETCH_TAXONOMIES,
  canuleTypes: FETCH_TAXONOMIES,
  channels: "fetchChannels",
  communityAffiliationTypes: FETCH_TAXONOMIES,
  communityEmergencySolutions: FETCH_TAXONOMIES,
  comorbidities: FETCH_TAXONOMIES,
  contacts: FETCH_TAXONOMIES,
  conversationTypes: FETCH_TAXONOMIES,
  deambulationDurations: FETCH_TAXONOMIES,
  deliveryStatuses: FETCH_TAXONOMIES,
  depPathologies: FETCH_TAXONOMIES,
  deviceMetrics: "fetchDeviceMetrics",
  deviceModes: FETCH_TAXONOMIES,
  deviceSettings: "fetchDeviceSettings",
  divisions: FETCH_TAXONOMIES,
  duration: FETCH_TAXONOMIES,
  entityDeliveryStatuses: FETCH_TAXONOMIES,
  entityInterventionStatuses: "fetchEntityInterventionStatuses",
  entityInterventionTypes: FETCH_TAXONOMIES,
  entityTypes: FETCH_TAXONOMIES,
  examenTypes: FETCH_TAXONOMIES,
  fileTypes: "fetchFileTypes",
  forfaits: "fetchForfaits",
  hba: FETCH_TAXONOMIES,
  interfaceTypesRespiration: FETCH_TAXONOMIES,
  intervenants: "fetchIntervenants",
  interventionStatuses: "fetchInterventionStatuses",
  interventionTypes: "fetchInterventionTypes",
  ordoTelesuiviConditions: FETCH_TAXONOMIES,
  pathologiesNpd: FETCH_TAXONOMIES,
  pathologiesRespi: FETCH_TAXONOMIES,
  patientContactModes: FETCH_TAXONOMIES,
  patientVentilationDurations: FETCH_TAXONOMIES,
  poles: "fetchPoles",
  pumps: "fetchPumps",
  prescribedTreatments: FETCH_TAXONOMIES,
  prescriberAdministrativeProtocols: FETCH_TAXONOMIES,
  prescriberEmailNotifications: FETCH_TAXONOMIES,
  prescriberTechnicalProtocolsValues: FETCH_TAXONOMIES,
  prestationTypes: FETCH_TAXONOMIES,
  productSubtypes: FETCH_TAXONOMIES,
  productTypes: FETCH_TAXONOMIES,
  provinces: FETCH_TAXONOMIES,
  recuperationMotifs: FETCH_TAXONOMIES,
  reminderTypes: FETCH_TAXONOMIES,
  renewalInformationChoices: FETCH_TAXONOMIES,
  riskAnalysisSections: FETCH_TAXONOMIES,
  riskAnalysisSubTypes: FETCH_TAXONOMIES,
  riskAnalysisTypes: FETCH_TAXONOMIES,
  roles: "fetchRoles",
  hsatStatuses: FETCH_TAXONOMIES,
  hsatInterpretations: FETCH_TAXONOMIES,
  specialities: FETCH_TAXONOMIES,
  stockMouvementTypes: FETCH_TAXONOMIES,
  supportSapLogLabels: FETCH_TAXONOMIES,
  supportSapLogTypes: FETCH_TAXONOMIES,
  supportTicketStatuses: FETCH_TAXONOMIES,
  supportTicketTypes: FETCH_TAXONOMIES,
  timeFrequencyUnits: FETCH_TAXONOMIES,
  titles: "fetchTitles",
  treatments: FETCH_TAXONOMIES,
  treatmentSubtypes: FETCH_TAXONOMIES,
  treatmentTypes: FETCH_TAXONOMIES,
  treatmentTypesAssociated: FETCH_TAXONOMIES,
  treatmentTypesCombinated: FETCH_TAXONOMIES,
  units: FETCH_TAXONOMIES,
  userEventTypes: FETCH_TAXONOMIES,
  userPrescriptionAddresses: "fetchUserPrescriptionAddresses",
  telesuiviManufacturers: FETCH_TAXONOMIES,
  faxReasons: FETCH_TAXONOMIES,
  sizeUnits: FETCH_TAXONOMIES,
  weightUnits: FETCH_TAXONOMIES,
  countries: FETCH_TAXONOMIES
};
