<template>
  <div class="container fullW fullH">
    <today-date class="margin--bot--l" />
    <div class="padding--top--bot--l flex--col flex--align--center">
      <img src="@ui/images/common/error-illustration-502.svg" alt="icon error 502" />
      <h2 class="text-align--center margin--bot--null">{{ titleError }}</h2>
      <p class="text-align--center max-width--s color--nc--50 line-height--m">
        {{ localize(localPath, "p--support") }}
      </p>
      <button class="ripple btn--primary btn--global margin--top--xs" @click="goBack">
        {{ localize(localPath, "button--goBack") }}
      </button>
    </div>
  </div>
</template>

<script>
import TodayDate from "@ui/components/text/today-date/todayDate";

const localPath = "views.401";

export default {
  name: "Unauthorized",
  components: {
    TodayDate
  },
  data() {
    return {
      localPath
    };
  },
  props: {
    errorMessage: {
      type: String,
      default() {
        return this.localize(localPath, "title");
      }
    },
    isRessource: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleError() {
      return this.isRessource ? this.localize(localPath, "p--ressource-denied") : this.errorMessage;
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    }
  }
};
</script>
