import { fetchReminders } from "@/modules/common/_api";

import bindReminderData from "@/helpers/_functions/bindReminderData";
import * as api from "../_api";

const state = {
  reminders: [],
  count: 0,
  currentCount: 0,
  formattedQuery: null
};

const getters = {
  getReminders: state => state.reminders,
  getRemindersCount: state => state.count,
  getCurrentRemindersCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_REMINDERS: (state, reminders) => {
    state.reminders = reminders;
  },
  SET_REMINDERS_COUNT: (state, count) => {
    state.count = count;
  },
  SET_CURRENT_REMINDERS_COUNT: (state, count) => {
    state.currentCount = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  createReminder(context, params) {
    return new Promise((resolve, reject) => {
      api
        .createReminder(bindReminderData(params))
        .then(() => {
          context.dispatch("fetchRemindersByPatientId", params.patientId).finally(() => {
            resolve();
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateReminder(context, params) {
    return new Promise((resolve, reject) => {
      api
        .updateReminder(bindReminderData(params))
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchReminders(context, { query, ...params }) {
    context.dispatch("formatQuery", query);
    return new Promise((resolve, reject) => {
      const { formattedQuery } = context.state;

      fetchReminders({ query: { ...formattedQuery }, ...params })
        .then(res => {
          const { results, count } = res.data.body;
          context.commit("SET_REMINDERS", results);
          context.commit("SET_CURRENT_REMINDERS_COUNT", count);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchRemindersByPatientId(context, patientId) {
    return new Promise((resolve, reject) => {
      api
        .fetchRemindersByPatientId([patientId])
        .then(res => {
          const { results, count } = res.data.body;
          context.commit("SET_REMINDERS", results);
          context.commit("SET_CURRENT_REMINDERS_COUNT", count);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchLastReminders(context, patientId) {
    return new Promise((resolve, reject) => {
      api
        .fetchLastReminders(patientId)
        .then(res => {
          const results = res?.data?.body?.results || [];
          context.commit("SET_REMINDERS", results);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchRemindersCount(context) {
    return new Promise((resolve, reject) => {
      api
        .fetchRemindersCount()
        .then(res => {
          const { count } = res.data.body;

          context.commit("SET_REMINDERS_COUNT", count || 0);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { patients, users, date, ...formattedQuery } = query;

    if (users) {
      formattedQuery.userIds = [];
      users.forEach(user => {
        formattedQuery.userIds.push(user._id);
      });
    }

    if (patients) {
      formattedQuery.patientIds = [];
      patients.forEach(patient => {
        formattedQuery.patientIds.push(patient._id);
      });
    }

    if (date) {
      formattedQuery.dateStart = date.start || undefined;
      formattedQuery.dateEnd = date.end || undefined;
    }

    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },

  exportReminders(context, data) {
    const { formattedQuery } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    const { sort, currentCount } = data;

    return api.exportReminders({
      sort,
      query: {
        ...formattedQuery,
        user: {
          email,
          firstname,
          lastname,
          _id
        },
        currentCount
      }
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
