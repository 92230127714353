import cloneDeep from "lodash/cloneDeep";
import displayResourceName from "@common/helpers/displayResourceName";
import getCountryLabelFromCountryCode from "@common/helpers/getCountryLabelFromCountryCode";
import calculateTotalPatientsCount from "@ui/helpers/_functions/calculateTotalPatientsCount";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localConstantPath = "constants.prescription-address-ids";

/**
 * Display prescriber infos in filter by prescriber
 */
const displayPrescriberResource = prescriber => {
  let rpps = "";

  if (prescriber.rpps) {
    rpps = ` - ${prescriber.rpps}`;
  }

  return `${displayResourceName(prescriber)} ${rpps}`;
};

/**
 * Display user (delegate, tech) infos in filter by delegate
 */
const displayUserResource = delegate => {
  let email = "";

  if (delegate.email) {
    email = ` - ${delegate.email}`;
  }

  return `${displayResourceName(delegate)} ${email}`;
};

const displayPatientLocation = location => {
  let result = "";

  if (location.locality) {
    result += `${location.locality}`;
  }

  if (location.locality && location.zipCode) {
    result += ` - `;
  }

  if (location.zipCode) {
    result += `${location.zipCode}`;
  }

  return result;
};

/**
 * Display patient infos in filter by patient
 */
const displayPatientResource = patient => {
  let location = "";

  if (patient.mainLocation?.locality || patient.mainLocation?.zipCode) {
    location += ` - ${displayPatientLocation(patient.mainLocation)}`;
  }

  return `${displayResourceName(patient)} ${location}`;
};

/**
 * Display community infos in filter by community
 */
const displayCommunityResource = community => (community ? `${community.label} (${community._id})` : "");

/**
 * Display enterprise infos in filter by enterprise
 */
const displayEntrepriseResource = community => (community ? `${community.label} (${community._id})` : "");

/**
 * Display locations infos in filter by zipCodes
 */
const displayLocationsResource = location =>
  location
    ? `${location.name}
${location.type === "city" && location.zipCode ? ` (${location.zipCode})` : ""}
${location.type === "city" && !location.zipCode ? location.departmentCode && ` (${location.departmentCode})` : ""}
${location.type === "department" && location.departmentCode ? ` (${location.departmentCode})` : ""}`
    : "--";

const displayZipCodesResource = originalZipCodes => {
  const zipCodes = cloneDeep(originalZipCodes);
  return zipCodes && zipCodes.length ? zipCodes.sort().join(", ") : "- -";
};

const displayIntervenantResource = (intervenant, complementaryInformation = null) => {
  if (!intervenant) {
    return "- -";
  }

  let content = `${displayResourceName(intervenant)}`;

  if (complementaryInformation === "externalId" && intervenant.externalId) {
    content += ` (${intervenant.externalId})`;
  }

  if (complementaryInformation === "zoneId" && intervenant.zoneId) {
    content += ` (${intervenant.zoneId})`;
  }

  return content;
};

const displayProductResource = product => {
  return `${product.alternativeLabel || product.label || "- -"} (${product._id})`;
};

const displayPrescriptionAddressResource = (prescriptionAddress, user = null) => {
  let selectedPrescriber = null;
  let address = "";
  // Only groups of prescribers can have prescribers
  if (user?.prescribers?.length) {
    selectedPrescriber = user.prescribers.find(prescriber => prescriber.rpps === prescriptionAddress.rpps);
  }

  if (selectedPrescriber) {
    address += `${displayResourceName(selectedPrescriber)} | `;
  }

  if (!prescriptionAddress) {
    return `${address}- -`;
  }

  const patientCount = calculateTotalPatientsCount(prescriptionAddress.patientsCount);

  address = `${prescriptionAddress.label}`;

  if (prescriptionAddress?.finess) {
    address += address ? ` - ${prescriptionAddress.finess}` : `${prescriptionAddress.finess}`;
  }

  if (prescriptionAddress.patientsCount) {
    address += ` - ${localize("helpers", "_functions.displayPrescriptionAddressCount", { count: patientCount }, patientCount)}`;
  }

  if (prescriptionAddress.typeId) {
    address += ` - ${localize(localConstantPath, `label--${prescriptionAddress.typeId}`)}`;
  }

  return address;
};

const displayPrescriptionAddressResourceMedpro = prescriptionAddress => {
  if (!prescriptionAddress) {
    return "- -";
  }

  const patientCount = calculateTotalPatientsCount(prescriptionAddress.patientsCount);

  return `${prescriptionAddress.label} ${
    prescriptionAddress.patientsCount
      ? ` - ${localize("helpers", "_functions.displayPrescriptionAddressCount", { count: patientCount }, patientCount)}`
      : ""
  }`;
};

const displayPrescriptionLocationResource = prescriptionAddress => {
  const { number, street, locality, zipCode, countryCode } = prescriptionAddress.location;
  return prescriptionAddress
    ? `${number} ${street} ${locality} ${zipCode} ${getCountryLabelFromCountryCode(countryCode)}(${prescriptionAddress.finess})`
    : "- -";
};

const displayChannelResource = channel => `${channel.label} (${channel.value})`;

const displayAlertTemplateResource = alertTemplate => `${alertTemplate.label}`;

const displayPrescribersGroupResource = prescribersGroup => `${prescribersGroup.name}`;

export {
  displayPrescriberResource,
  displayPatientResource,
  displayPatientLocation,
  displayCommunityResource,
  displayEntrepriseResource,
  displayUserResource,
  displayLocationsResource,
  displayZipCodesResource,
  displayIntervenantResource,
  displayProductResource,
  displayPrescriptionAddressResource,
  displayPrescriptionAddressResourceMedpro,
  displayPrescriptionLocationResource,
  displayChannelResource,
  displayAlertTemplateResource,
  displayPrescribersGroupResource
};
