import { iconInterventionOutlined } from "@ui/components/icons";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

import subMenuInterventionsMedpro from "./sub-menus/subMenuInterventionsMedpro";
import subMenuMyWorkMedpro from "./sub-menus/subMenuMyWorkMedpro";

const localPath = "menus.administration.interventions-tours";

const menuAppointments = {
  title: localize(localPath, "title"),
  slug: "interventions-tournees",
  icon: iconInterventionOutlined,
  disabled: false,
  items: [subMenuInterventionsMedpro, subMenuMyWorkMedpro]
};

export default menuAppointments;
