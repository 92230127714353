import http from "@/services/http";

export const editPatientInformations = (id, data) => http.put(`/patients/${id}`, data);

export const editInsured = (id, data) => http.put(`/insureds/${id}`, data);

export const editPatientContract = (id, data) => http.put(`/contracts/${id}`, data);

export const updatePatientActiveContractsNextConsultationDate = (nextConsultationDate, patientId) =>
  http.put(`/patients/${patientId}/next-consultation-date`, { nextConsultationDate });

/**
 * @param {Object} data
 * @param {String} id
 * @returns {<Object>} filled document
 */
export const getRenewalDocumentById = id => http.get(`/contracts/get-renewal-document-by-id/`, { params: { id } });

export const addCustomProtocol = data => http.post(`/custom-protocols`, data);

export const addProductsReminder = data => http.post(`/products-reminders`, data);

/**
 * @author Alexandre POIRRIER
 * @date 2025-02-26
 *
 * @param {String} id patient id
 * @param {Object} data
 *
 * @returns {Promise<Object>}
 */
export const editPatientFromIntervention = (id, data) => http.put(`/patients/${id}/from-intervention`, data);
