import http from "@/services/http";

export const exportReplenishmentPacks = params => http.get(`/deliveries/export-replenishment-packs/file`, { params });

export const exportEstimationsConsommations = params => http.get(`/deliveries/export-consommations/file`, { params });

export const exportDeliveredPairableDevices = params => http.get("/deliveries/export-delivered-pairable-devices/file", { params });

export const exportMouvementsStock = params => http.get("/deliveries/export-mouvements-stock/file", { params });

export const getExport = id => http.get(`/exports/${id}`, { timeout: 60000 });
