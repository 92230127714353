/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import {
  searchForfaits,
  searchProducts,
  searchIntervenants,
  searchEnterprises,
  fetchForfaits,
  uploadPrivateFile,
  fetchFile,
  fetchPatient,
  fetchZone,
  fetchPrescribers
} from "@/modules/common/_api";
import formatPatientName from "@/helpers/_functions/patient/formatPatientName";

import * as api from "../_api";

const state = {
  contract: {}
};

const getters = {
  contract: state => state.contract
};

const mutations = {
  SET_CONTRACT: (state, contract) => {
    state.contract = contract;
  }
};

const actions = {
  searchForfaits(context, { division, input }) {
    return new Promise((resolve, reject) => {
      searchForfaits({ query: { input, division, active: true }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPumps(context, input) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { divisions: ["D"], productSubtypeIds: ["pump"], ignoreVeoPumpsDuplicate: true, onlyLocationCode: true, input },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(response => {
          resolve(response?.data?.body || []);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchIntervenantsWithExternalId(context, input) {
    return new Promise((resolve, reject) => {
      searchIntervenants({ query: { input, active: true, withExternalId: true, withEmail: true }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchIntervenantsWithoutExternalId(context, input) {
    return new Promise((resolve, reject) => {
      searchIntervenants({
        query: { input, active: true, withExternalId: false, withEmail: true },
        skip: 0,
        limit: 40,
        source: "local"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchIntervenantsIdec(context, input) {
    return new Promise((resolve, reject) => {
      searchIntervenants({ query: { input, active: true, withSapEmployeeId: true }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchEnterprises(context, input) {
    return new Promise((resolve, reject) => {
      searchEnterprises({ query: { input }, skip: 0, limit: 10, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchForfaits(context, query) {
    const params = { query };
    return new Promise((resolve, reject) => {
      fetchForfaits(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setPatientZone(context) {
    return new Promise(resolve => {
      const { patient } = context.state.contract;
      const { zoneId } = patient;

      if (!zoneId) {
        resolve();
        return;
      }

      fetchZone(zoneId)
        .then(res => {
          patient.zoneId = zoneId;
          patient.zone = res.data && res.data.body ? res.data.body : null;
          context.commit("SET_CONTRACT", { ...context.state.contract, patient });
        })
        .catch(() => {
          patient.zoneId = zoneId;
          patient.zone = null;
          context.commit("SET_CONTRACT", { ...context.state.contract, patient });
        })
        .finally(() => {
          resolve();
        });
    });
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  resetContract(context) {
    context.commit("SET_CONTRACT", {});
  },
  resetContractKeys(context, keys) {
    const { contract } = context.state;
    keys.forEach(key => {
      delete contract[key];
    });
    context.commit("SET_CONTRACT", contract);
  },
  updateContract(context, data) {
    const { contract } = context.state;

    if (data?.forfait?.forfaitId === "hsat" && data?.forfait?.installDate) {
      data.forfait.depDate = data.forfait.installDate;
    }

    Object.assign(contract, data);
    context.commit("SET_CONTRACT", contract);
  },
  addContract(context, data) {
    return api.addContract(data);
  },
  linkIntervenantToPatient(context, { mainTech, idecTech }) {
    return new Promise((resolve, reject) => {
      const { patient } = context.state.contract;

      /* If no mainTech is provided, we skip because we can't fetch the zone, if no idecTech we also skip */
      if (!mainTech && !idecTech) {
        resolve();
        return;
      }

      /* If patient has already a zone (and that zone is present in Bastide Access), we can skip this step because zone can't be edited from Bastide Access */
      if (mainTech && patient.zoneId && patient.zone) {
        resolve();
        return;
      }

      api
        .linkIntervenantToPatient(patient._id, {
          externalId: mainTech?.externalId,
          mainIntervenantId: idecTech?.sapEmployeeId || mainTech?.mainIntervenantId
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatient(context, patientId) {
    return new Promise((resolve, reject) => {
      fetchPatient(patientId)
        .then(res => {
          resolve(formatPatientName(res.data.body));
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPrescriberInfos(context, rpps) {
    return new Promise((resolve, reject) => {
      fetchPrescribers({ query: { rpps } })
        .then(res => {
          resolve(res.data.body);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
