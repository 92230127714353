const bindReminderData = data => {
  if (!data || typeof data !== "object" || !Object.keys(data).length) {
    return {};
  }

  const { _id, date, details, user, userId, patient, patientId, channelId, typeId, status, active } = data;

  const reminder = {
    date,
    details,
    userId: user._id || userId,
    typeId,
    patientId: patient?._id || patientId,
    channelId: patient?.channelId || channelId,
    active,
    timezone: user.timezone
  };

  if (_id) {
    reminder._id = _id;
  }

  if (status) {
    reminder.status = status;
  }

  return reminder;
};

export default bindReminderData;
