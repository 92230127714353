/* eslint-disable no-shadow */
import * as api from "@/modules/exports/_api";

const actions = {
  async exportReplenishmentPacks(context, data) {
    const { _id, email, firstname, lastname } = context.rootState.login.user;

    return api.exportReplenishmentPacks({
      startDate: data.startDate,
      user: {
        email,
        firstname,
        lastname,
        _id
      }
    });
  },
  exportEstimationsConsommations(context, data) {
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    return api.exportEstimationsConsommations({
      startDate: data.exportDate.start,
      endDate: data.exportDate.end,
      user: {
        email,
        firstname,
        lastname,
        _id
      }
    });
  },
  exportDeliveredPairableDevices(context, data) {
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    return api.exportDeliveredPairableDevices({
      startDate: data.exportDate.start,
      endDate: data.exportDate.end,
      user: {
        email,
        firstname,
        lastname,
        _id
      }
    });
  },
  exportMouvementsStock(context, data) {
    const { _id, email, firstname, lastname } = context.rootState.login.user;
    return api.exportMouvementsStock({
      startDate: data.exportDate.start,
      endDate: data.exportDate.end,
      user: {
        email,
        firstname,
        lastname,
        _id
      }
    });
  },
  getExport(context, id) {
    return api.getExport(id);
  }
};

export default { state: {}, getters: {}, mutations: {}, actions, namespaced: true };
