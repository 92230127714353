import http from "@/services/http";

/**
 * @param {Object} data
 * @returns {Object} response
 */
export const createUserEvent = data => http.post(`/user-events/`, data);

/**
 * @param {String} id
 * @param {Object} data
 * @returns {Object} response
 */
export const editUserEvent = (id, data) => http.put(`/user-events/${id}`, data);

/**
 * @param {Object} params
 * @returns {Object} response
 */
export const fetchUserEvents = params => http.get("/user-events", { params });

/**
 * @param {String} id
 * @returns {Object} response
 */
export const deleteUserEvent = id => http.delete(`/user-events/${id}`);
