import { fetchInterventions, fetchUserEvents } from "@/modules/common/_api";
import { fetchEntityInterventions } from "@/modules/entity-interventions/common/_api";

const state = {
  interventions: [],
  userEvents: [],
  entityInterventions: []
};

const getters = {
  interventions: state => state.interventions,
  userEvents: state => state.userEvents,
  entityInterventions: state => state.entityInterventions
};

const mutations = {
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_USER_EVENTS: (state, userEvents) => {
    state.userEvents = userEvents;
  },
  SET_ENTITY_INTERVENTIONS: (state, entityInterventions) => {
    state.entityInterventions = entityInterventions;
  }
};

const actions = {
  fetchInterventions(context, params) {
    return new Promise((resolve, reject) => {
      fetchInterventions(params)
        .then(res => {
          context.commit("SET_INTERVENTIONS", res.data?.body?.results || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUserEvents(context, params) {
    return new Promise((resolve, reject) => {
      fetchUserEvents(params)
        .then(res => {
          context.commit("SET_USER_EVENTS", res.data?.body || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchEntityInterventions(context, params) {
    return new Promise((resolve, reject) => {
      fetchEntityInterventions(params)
        .then(res => {
          context.commit("SET_ENTITY_INTERVENTIONS", res.data?.body?.results || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
