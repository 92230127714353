import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async createPrescriptionAfterAmeliproSigning(context, data) {
    return api.createPrescriptionAfterAmeliproSigning({
      data
    });
  },
  async fetchPendingAmeliproPrescriptions(context) {
    const response = await api.fetchPendingAmeliproPrescriptions();

    const prescriptionAmeliproToUpdate =
      response?.data?.body?.map(prescription => {
        return {
          prescriptionId: prescription._id,
          contractId: prescription.contractId,
          initiale: prescription.initiale,
          ameliproDocumentId: prescription.ameliproDocumentId,
          ameliproStatusId: prescription.ameliproStatusId
        };
      }) || [];

    return prescriptionAmeliproToUpdate;
  },
  updateAmeliproPrescriptionStatus(context, data) {
    return new Promise((resolve, reject) => {
      const { prescriptionId, ...dataToUpdate } = data;

      api
        .updateAmeliproPrescriptionStatus(prescriptionId, dataToUpdate)
        .then(resp => {
          return resolve(resp.data.successMessage);
        })
        .catch(error => {
          return reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
