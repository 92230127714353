import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Object} data{}.body{count:{}, results: {}}
 */

// Use POST method and send params into body to avoid error 414
export default params =>
  new Promise((resolve, reject) => {
    http
      .post("/interventions/get-list-for-planning", params, { timeout: 60000 })
      .then(res => {
        if (!res.data.body) {
          /* Because of on 204 status, res.data is an empty string */
          return resolve({
            data: {
              body: {
                count: 0,
                results: []
              }
            }
          });
        }
        return resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
