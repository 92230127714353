/* eslint-disable no-shadow */
import eachSeries from "async/eachSeries";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import isBoolean from "lodash/isBoolean";
import moment from "moment";

import {
  fetchFile,
  fetchPatient,
  fetchDeliveries,
  fetchForfaitsDeliveries,
  fetchPrescriptionAddresses,
  fetchPrescribers,
  fetchZone,
  fetchAlerts,
  fetchAlertTemplates,
  fetchTelesuivi,
  fetchCustomProtocol,
  fetchProductsReminder,
  fetchProductsReminders,
  fetchEquipementAvailability,
  pairEquipment,
  unPairEquipment,
  searchPrescribers,
  uploadPrivateFile
} from "@/modules/common/_api";

import sortInterventionsByPlanification from "@/helpers/_functions/patient/sortInterventionsByPlanification";
import buildContactsList from "@/helpers/_functions/patient/buildContactsList";
import formatPatientName from "@/helpers/_functions/patient/formatPatientName";
import formatNewConversationData from "@/helpers/_functions/conversations/formatNewConversationData";

import * as api from "../_api";
import ordoclicStore from "./ordoclic";

const removeOldDeliveredConsommables = require("@common/helpers/deliveries/removeOldDeliveredConsommables");

const {
  alertsRights: { ACCESS_ALERT },
  prescribersRights: { ACCESS_PRESCRIBER }
} = require("@common/services/acm/rights");
const deliveryStatuses = require("@common/constants/deliveryStatuses");

const features = require("@/services/features");

/* Factory function to create state on demand, so we can use it on destroy to reset clean state */
const getDefaultState = () => {
  return {
    patient: {},
    alerts: [],
    alertTemplates: [],
    telesuivi: [],
    interventions: [],
    contacts: [],
    customProtocol: {},
    productsReminder: {},
    productsReminders: [],
    ordonnances: [],
    loading: {
      patient: false,
      contracts: false,
      alerts: false,
      telesuivi: false,
      interventions: false,
      commonFetch: false,
      appairage: false
    }
  };
};

const state = getDefaultState();

const getters = {
  patient: state => state.patient,
  telesuivi: state => state.telesuivi,
  alerts: state => state.alerts,
  alertTemplates: state => state.alertTemplates,
  interventions: state => state.interventions,
  loading: state => state.loading,
  contacts: state => state.contacts,
  ordonnances: state => state.ordonnances,
  customProtocol: state => state.customProtocol,
  productsReminder: state => state.productsReminder,
  productsReminders: state => state.productsReminders
};

const mutations = {
  SET_PATIENT: (state, patient) => {
    state.patient = patient;
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_CONTRACTS: (state, contracts) => {
    state.patient.contracts = contracts;
  },
  SET_TELESUIVI: (state, telesuivi) => {
    state.telesuivi = telesuivi;
  },
  SET_ALERTS: (state, alerts) => {
    state.alerts = alerts;
  },
  SET_ALERT_TEMPLATES: (state, alertTemplates) => {
    state.alertTemplates = alertTemplates;
  },
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_CUSTOM_PROTOCOL: (state, customProtocol) => {
    state.customProtocol = customProtocol;
  },
  SET_PRODUCTS_REMINDER: (state, productsReminder) => {
    state.productsReminder = productsReminder;
  },
  SET_PRODUCTS_REMINDERS: (state, productsReminders) => {
    state.productsReminders = productsReminders;
  },
  ADD_CONTACTS: (state, contacts) => {
    state.contacts.push(contacts);
  },
  ADD_ORDONNANCES: (state, ordonnances) => {
    state.ordonnances.push(ordonnances);
  },
  UPDATE_LOADING: (state, loadingItems) => {
    state.loading = { ...state.loading, ...loadingItems };
  }
};

const actions = {
  /**
   * fetchPatient is an orchestrator. Its job is to handle the fetching of each resources in the right order,
   * and to set the loading state of each parts of the patients view accordingly.
   * @param {Object} context
   * @param {String} patientId
   */
  fetchPatient(context, { patientId }) {
    return new Promise((resolve, reject) => {
      context.dispatch("resetPatient");

      context
        .dispatch("setPatient", patientId)
        .then(() => {
          context
            .dispatch("fetchZone")
            .catch(() => {})
            .finally(() => {
              const promises = [];
              promises.push(context.dispatch("setTelesuivi", patientId), context.dispatch("setContracts"));

              if (features.isInterventionsEnabled()) {
                promises.push(context.dispatch("setInterventions", patientId));
              }

              if (features.isTelesuiviAlertsEnabled() && context.rootGetters["login/$user"].can(ACCESS_ALERT)) {
                promises.push(context.dispatch("setAlerts", patientId));
              }

              Promise.all(promises)
                .then(() => {
                  resolve();
                })
                .catch(errSetDataPatient => {
                  reject(errSetDataPatient);
                })
                .finally(() => context.commit("UPDATE_LOADING", { commonFetch: false }));
            });
        })
        .catch(errSetPatient => reject(errSetPatient));
    });
  },
  setPatient(context, patientId) {
    context.commit("UPDATE_LOADING", { patient: true });
    return new Promise((resolve, reject) => {
      fetchPatient(patientId)
        .then(res => {
          const patient = formatPatientName(res.data.body);
          if (!patient.insuredId) {
            context.commit("SET_PATIENT", patient);
            context.commit("UPDATE_LOADING", { patient: false });
            return resolve();
          }
          context
            .dispatch("fetchInsured", patientId)
            .then(insured => {
              patient.insured = insured;
              context.commit("SET_PATIENT", patient);
              context.commit("UPDATE_LOADING", { patient: false });
            })
            .catch(() => {
              context.commit("SET_PATIENT", patient);
              context.commit("UPDATE_LOADING", { patient: false });
            })
            .finally(() => {
              resolve();
            });
        })
        .catch(err => {
          reject(err);
          context.commit("UPDATE_LOADING", { patient: false });
        });
    });
  },
  setLoadingPatient(context, loading) {
    context.commit("UPDATE_LOADING", { patient: loading });
  },
  setContracts(context) {
    context.commit("UPDATE_LOADING", { contracts: true, contacts: true, ordonnances: true });
    return new Promise((resolve, reject) => {
      const contracts = [];
      const { patient } = context.state;
      if (!patient.contracts) {
        context.commit("UPDATE_LOADING", { contracts: false, contacts: false, ordonnances: false });
        resolve();
        return;
      }

      eachSeries(
        patient.contracts,
        (contract, nextContract) => {
          const promises = [
            context.dispatch("fetchOrdonnances", contract.id),
            context.dispatch("fetchDeliveries", contract.id),
            context.dispatch("fetchPrescribers", [contract.mainPrescriptionAddressId])
          ];

          if (contract.contactIds?.length) {
            promises.push(context.dispatch("common/fetchContacts", { query: { ids: contract.contactIds, active: true } }, { root: true }));
          }

          Promise.allSettled(promises)
            .then(([resFetchOrdonnances, resFetchDeliveries, resFetchPrescribers, resFetchContacts]) => {
              const prescriber = resFetchPrescribers?.value?.length ? resFetchPrescribers?.value[0] : null;
              const addedProperties = {
                contacts: buildContactsList({
                  pole: contract.pole || {},
                  mainTech: patient.mainTech,
                  prescriptionAddress: contract.prescriptionAddress || {},
                  secondaryPrescriptionAddress: contract.secondaryPrescriptionAddress || {},
                  referralPrescriberAddress: contract.referralPrescriberAddress || {},
                  familyPrescriberAddress: contract.familyPrescriberAddress || {},
                  interpretingPhysician: contract.interpretingPhysician || {},
                  otherPrescriptionAddresses: contract.otherPrescriptionAddresses || [],
                  contacts: resFetchContacts?.value || []
                }),
                ordonnances: resFetchOrdonnances?.value || [],
                deliveries: resFetchDeliveries?.value || [],
                prescriber,
                metrics: contract.metrics ? contract.metrics : {}
              };

              contracts.push({ ...contract, ...addedProperties });
            })
            .finally(() => {
              return nextContract();
            });
        },
        errLoopContracts => {
          if (errLoopContracts) return reject(errLoopContracts);

          contracts.sort((current, next) => new Date(next.prescrDate) - new Date(current.prescrDate));

          context.commit("SET_CONTRACTS", contracts);
          /* Once the products are mapped with the contracts, they can be displayed */
          context.commit("UPDATE_LOADING", { contracts: false, contacts: false, ordonnances: false });

          return resolve();
        }
      );
    });
  },
  setInterventions(context, patientId) {
    context.commit("UPDATE_LOADING", { interventions: true });
    return new Promise((resolve, reject) => {
      api
        .fetchInterventions(patientId)
        .then(res => {
          const sortedInterventions = sortInterventionsByPlanification(res.data.body, context.rootState.login.user);
          context.commit("SET_INTERVENTIONS", sortedInterventions);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { interventions: false });
        });
    });
  },
  setTelesuivi(context, patientId) {
    context.commit("UPDATE_LOADING", { telesuivi: true });
    return new Promise((resolve, reject) => {
      fetchTelesuivi({
        query: {
          patientId,
          startDate: moment().subtract(1, "year").startOf("isoWeek").toISOString(),
          endDate: moment().toISOString()
        }
      })
        .then(res => {
          context.commit("SET_TELESUIVI", res && res.data ? res.data.body : []);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { telesuivi: false });
        });
    });
  },
  setAlerts(context, patientId) {
    return new Promise((resolve, reject) => {
      if (!context.rootGetters["login/$user"].can(ACCESS_ALERT)) {
        context.commit("UPDATE_LOADING", { alerts: false });
        reject();
      } else {
        context.commit("UPDATE_LOADING", { alerts: true });
        fetchAlerts({
          query: {
            patientIds: [patientId]
          }
        })
          .then(responseAlerts => {
            const alerts = responseAlerts.data && responseAlerts.data.body ? responseAlerts.data.body.results : [];
            context.commit("SET_ALERTS", alerts);
            const alertsTemplateIds = [...new Set(alerts.map(item => item.templateId))];
            fetchAlertTemplates({ query: { ids: alertsTemplateIds } })
              .then(responseTemplates => {
                const templates = responseTemplates.data && responseTemplates.data.body ? responseTemplates.data.body.results : [];
                context.commit("SET_ALERT_TEMPLATES", templates);
                resolve();
              })
              .catch(err => {
                reject(err);
              })
              .finally(() => {
                context.commit("UPDATE_LOADING", { alerts: false });
              });
          })
          .catch(err => {
            context.commit("UPDATE_LOADING", { alerts: false });
            reject(err);
          });
      }
    });
  },
  updateAlerts(context, data) {
    return api.editAlerts(data);
  },
  incrementNoticeViewCount(context, noticeId) {
    return api.incrementNoticeViewCount({ noticeId });
  },
  fetchDeliveries(context, contractId) {
    return new Promise((resolve, reject) => {
      fetchDeliveries({
        query: {
          active: true,
          contractId,
          statusIds: [deliveryStatuses.delivered, deliveryStatuses.toBeRemoved, deliveryStatuses.removed],
          show: true
        },
        sort: { deliveryDate: -1 },
        withNotices: __TARGET__ === "extranet"
      })
        .then(res => {
          const deliveries = res.data.body.results || [];

          /* We need to remove deliveries without product (these are forfait deliveries) */
          const filteredDevicesDeliveries = deliveries.filter(delivery => delivery.product?.typeId === "machine");
          const filteredSuppliesDeliveries = deliveries.filter(delivery => delivery.product?.typeId !== "machine");

          /* For each product ID, we only need to display the most recent so we remove duplicate. As the array is sorted by deliveryDate, the most recent will be kept */
          const uniqueAndRecentSuppliesDeliveries = removeOldDeliveredConsommables(uniqBy(filteredSuppliesDeliveries, "productId"));

          resolve([...filteredDevicesDeliveries, ...uniqueAndRecentSuppliesDeliveries]);
        })
        .catch(err => reject(err));
    });
  },
  async fetchForfaitsDeliveriesForPackagesHistory(context, contractId) {
    const res = await fetchForfaitsDeliveries({
      query: {
        contractId,
        statusIds: [deliveryStatuses.delivered, deliveryStatuses.removed]
      },
      sort: { creationDate: -1 }
    });

    return res.data?.body || [];
  },
  fetchOrdonnances(context, contractId) {
    return new Promise((resolve, reject) => {
      if (isEmpty(contractId)) {
        resolve();
        return;
      }

      api
        .fetchOrdonnances({ query: { contractId } })
        .then(res => resolve(res.data.body))
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescriptionAddresses(context, prescriptionAddressIds) {
    return new Promise((resolve, reject) => {
      if (isEmpty(prescriptionAddressIds)) {
        resolve([]);
        return;
      }

      fetchPrescriptionAddresses({ query: { prescriptionAddresses: prescriptionAddressIds } })
        .then(res => resolve(res.data && res.data.body ? res.data.body.results : []))
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribers(context, prescriptionAddressIds) {
    return new Promise((resolve, reject) => {
      if (isEmpty(prescriptionAddressIds) || !context.rootGetters["login/$user"].can(ACCESS_PRESCRIBER)) {
        resolve([]);
        return;
      }

      fetchPrescribers({ query: { prescriptionAddresses: prescriptionAddressIds } })
        .then(res => resolve(res.data && res.data.body ? res.data.body.results : []))
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchInsured(context, patientId) {
    return new Promise((resolve, reject) => {
      api
        .fetchInsured(patientId)
        .then(res => {
          const insured = res.data.body;
          if (!insured) return resolve(undefined);
          return resolve(insured);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchZone(context) {
    return new Promise((resolve, reject) => {
      let zoneId = null;

      if (context.state.patient.zoneId) {
        zoneId = context.state.patient.zoneId;
      }

      if (context.state.patient.insured && context.state.patient.insured.zoneId) {
        zoneId = context.state.patient.insured.zoneId;
      }

      if (!zoneId) {
        resolve();
        return;
      }

      fetchZone(zoneId)
        .then(res => {
          const zone = res.data && res.data.body ? res.data.body : null;
          const mainTech = zone && zone.mainTech ? zone.mainTech : null;
          context.commit("SET_PATIENT", { ...context.state.patient, zone, mainTech });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomProtocol(context, params) {
    return new Promise((resolve, reject) => {
      fetchCustomProtocol(params.query)
        .then(res => {
          if (res?.data?.body) {
            context.commit("SET_CUSTOM_PROTOCOL", res.data.body);
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProductsReminder(context, params) {
    return new Promise((resolve, reject) => {
      fetchProductsReminder(params.query)
        .then(res => {
          if (res?.data?.body) {
            context.commit("SET_PRODUCTS_REMINDER", res.data.body);
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProductsReminders(context, params) {
    return new Promise((resolve, reject) => {
      fetchProductsReminders(params.query)
        .then(res => {
          if (res?.data?.body) {
            context.commit("SET_PRODUCTS_REMINDERS", res.data.body?.results || []);
          }

          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetPatient(context) {
    context.commit("RESET_STATE");
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  addConversation(context, data) {
    return new Promise((resolve, reject) => {
      if (__TARGET__ === "prescriber") {
        // eslint-disable-next-line no-param-reassign
        data.prescriber = context.rootGetters["login/$user"];
        const { conversation, message } = formatNewConversationData(data);
        api
          .createConversationPrescriber(conversation, message)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      } else if (__TARGET__ === "extranet") {
        // eslint-disable-next-line no-param-reassign
        data.patient = context.rootGetters["login/$user"];
        const { conversation, message } = formatNewConversationData(data);

        api
          .createConversationPatient(conversation, message)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject();
      }
    });
  },
  desappairageContractPatient(context, contractId) {
    return new Promise((resolve, reject) => {
      api
        .editPatientContract(contractId, {
          telesuiviAppairage: false,
          telesuiviEndDate: moment().toISOString(),
          telesuiviId: "",
          telesuiviSource: ""
        })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
  forcePlanification(context, contractId) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { interventions: true });
      const patientId = context.state.patient._id;
      api
        .forcePlanification(contractId)
        .then(response => {
          api
            .fetchInterventions(patientId)
            .then(interventionsResponse => {
              const sortedInterventions = sortInterventionsByPlanification(interventionsResponse.data.body);
              context.commit("SET_INTERVENTIONS", sortedInterventions);
              resolve(response);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING", { interventions: false });
        });
    });
  },
  generateExitAttestation(context, contractId) {
    return new Promise((resolve, reject) => {
      api
        .generateExitAttestation(contractId)
        .then(response => {
          resolve(response);
        })
        .catch(errorReport => {
          reject(errorReport);
        });
    });
  },
  createExtranetAccount(context, data) {
    return new Promise((resolve, reject) => {
      api
        .createExtranetAccount(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkAppairage(context, payload) {
    return new Promise((resolve, reject) => {
      fetchEquipementAvailability({
        ...payload,
        channelId: context.state.patient.contracts.find(contract => contract.id === payload.contractId)?.channelId
      })
        .then(response => {
          if (isBoolean(response?.data?.body?.available)) {
            resolve(response.data.body.available);
          }

          reject();
        })
        .catch(() => {
          reject();
        });
    });
  },
  validateAppairage(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { appairage: true });

      pairEquipment({
        ...payload,
        intervenantId: context.rootGetters["login/$user"]?._id,
        channelId: context.state.patient.contracts.find(contract => contract.id === payload.contractId)?.channelId
      })
        .then(() => {
          context.dispatch("fetchPatient", { patientId: context.state.patient._id }).finally(() => {
            context.commit("UPDATE_LOADING", { appairage: false });
            resolve();
          });
        })
        .catch(errAppairage => {
          context.commit("UPDATE_LOADING", { appairage: false });
          reject(errAppairage.data.errorMessage);
        });
    });
  },
  removeAppairage(context, { delivery, channelId }) {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", { appairage: true });

      unPairEquipment({ delivery, channelId })
        .then(() => {
          context.dispatch("fetchPatient", { patientId: context.state.patient._id }).finally(() => {
            context.commit("UPDATE_LOADING", { appairage: false });
            resolve();
          });
        })
        .catch(errDesappairage => {
          context.commit("UPDATE_LOADING", { appairage: false });
          reject(errDesappairage);
        });
    });
  },
  updateCpapPackage(context, { contractId, packageUpdate }) {
    return api.updateCpapPackage(contractId, packageUpdate);
  },
  getPapReportDataForModalAutocompletion(context, { contractId, compliancePeriod }) {
    return api.getPapReportDataForModalAutocompletion(contractId, compliancePeriod);
  },
  createPapReport(context, dataToSave) {
    return api.createPapReport(dataToSave);
  },

  ...ordoclicStore.actions
};

export default { state, getters, mutations, actions, namespaced: true };
