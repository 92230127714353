import http from "@/services/http";

export const createPrescriptionAfterAmeliproSigning = data => http.post("/renewals/amelipro", data);

export const fetchPendingAmeliproPrescriptions = () => http.get(`/renewals/pending-amelipro-renewals`);

/**
 * Update renewal status of amelipro
 * @author MaximeItaf
 * @date 2025-01-22
 *
 * @param {String} prescriptionId
 * @param {Object} data
 * * @param {String}  data.ameliproStatusId
 * * @param {Boolean} data.initial - Is initial prescription
 * * @param {Boolean} data.ameliproStatusManuallyUpdated - define if status is manually updated
 * * @param {String}  data.contractId
 * @returns {Promise}
 */
export const updateAmeliproPrescriptionStatus = (prescriptionId, data) => http.put(`/renewals/${prescriptionId}/amelipro-status`, data);
