module.exports = {
  authBan: true,
  emailNotifications: true,
  interventions: true,
  reminders: true,
  planification: {
    onContractCreation: true
  },
  faxes: true,
  sleepStudies: true,
  telesuiviAlerts: true,
  patientTelesuiviSynchroButton: true,
  productOrigin: true,
  support: true,
  resourceOverlapping: true,
  sms: {
    service: false,
    synchro: false,
    auto: false,
    onRequest: false
  },
  patient: {
    checkSsidDuplication: true
  }
};
