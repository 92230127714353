/**
 * Return formated data for creation or updating user event
 *
 * @param {Object} userEvent user event's form data
 * @returns {Object} formated user event
 */
const bindUserEventData = userEvent => {
  const { user, ...data } = userEvent;

  if (user) {
    data.userId = user._id;
  }
  return data;
};

export default bindUserEventData;
